<template>
    <div class="modal secondary category_preview" v-if="modelValue">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <h1 class="m-0">{{ habit.name }}</h1>
                <button class="close_btn ml-auto" @click="closeModal()"><i class="fas fa-times"></i></button>
            </div>
            <div class="modal_body">
                <div class="que_ans">
                    <h4 v-show="habit.description">{{ habit.description }}</h4>
                    <div class="ques_details">
                        <h3 class="sub_header">Requirements</h3>
                        <p class="info">{{ habit.requirements }}</p>
                        <div v-if="habitTracking.recurring_details.recurring_type == 'weekly'">
                            <h3 class="sub_header">Days this week I was compliant:</h3>
                            <ul class="days mb-2">
                                <li v-for="items in weekDays.options" :key="items.id">
                                    <label :for="`habit-week-${items.id}`">
                                        <input type="checkbox" :id="`habit-week-${items.id}`" v-model="items.status" hidden>
                                        <span class="weekdays"><i class="fas fa-check mr-2" v-if="items.status"></i>{{items.label}}</span>
                                    </label>
                                </li>
                                <li>
                                    <div class="goal_btn">
                                        <i class="fas fa-trophy mr-2"></i>
                                        {{ `Goal is ${ habit.success_goal > 1 ? habit.success_goal+' days' : habit.success_goal+' day' }` }}
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div v-if="habitTracking.recurring_details.recurring_type == 'daily'">
                            <div class="setting_wpr">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Completed?</label>
                                        <div class="grp_wpr">
                                            <div class="box_item">
                                                <label :for="`perform-yes-${habit.id}`">
                                                    <input type="radio" value="yes" :id="`perform-yes-${habit.id}`" :name="`perform-${habit.id}`" class="mr-2">
                                                    <p>Yes</p>
                                                </label>
                                            </div>
                                            <div class="box_item">
                                                <label :for="`perform-no-${habit.id}`">
                                                    <input type="radio" value="no" :id="`perform-no-${habit.id}`" :name="`perform-${habit.id}`" class="mr-2">
                                                    <p>No</p>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="setting_wpr" v-if="habit.submit_proof == 1">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Proof</label>
                                    <div class="upload_image m-0">
                                        <image-upload image-type="all" upload-text="Image" v-model="logo"  :is-avatar="false" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import { defineAsyncComponent } from 'vue'

    const ImageUpload = defineAsyncComponent(() => import('@/components/image-library/ImageUpload'))

    export default {
        data () {
            return {
                logo: '',
                weekDays: {
                    mode: 'single',
                    value: ['Mon'],
                    options:[
                        {
                            id: 1,
                            value: 'Mon',
                            label: 'Mon',
                            status: false,
                        },
                        {
                            id: 2,
                            value: 'Tue',
                            label: 'Tue',
                            status: false,
                        },
                        {
                            id: 3,
                            value: 'Wed',
                            label: 'Wed',
                            status: false,
                        },
                        {
                            id: 4,
                            value: 'Thu',
                            label: 'Thu',
                            status: false,
                        },
                        {
                            id: 5,
                            value: 'Fri',
                            label: 'Fri',
                            status: false,
                        },
                        {
                            id: 6,
                            value: 'Sat',
                            label: 'Sat',
                            status: false,
                        },
                        {
                            id: 7,
                            value: 'Sun',
                            label: 'Sun',
                            status: false,
                        },
                    ]
                },
            }
        },

        props: {
            modelValue: Boolean,
            habit: Object,
        },

        emits: ['update:modelValue'],

        components: {
            ImageUpload
        },

        watch: {
            modelValue (val) {
                if (val) {
                    const vm = this;

                    vm.logo = '';
                }
            }
        },

        computed: mapState({
            habitTracking: state => state.habitTrackingModule.selectedHabitTracking,
        }),

        methods: {
            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            }
        }
    }
</script>

<style scoped>
    .category_preview .modal_container {
        display: block;
        padding: 0;
    }

    .category_preview .modal_header {
        padding: 15px 30px;
    }

    .category_preview .modal_body {
        padding: 0 30px 30px 30px;
        flex-direction: column;
        max-height: 550px;
    }

    .modal.add_question .modal_header {
        padding: 0 45px;
    }

    .modal.add_question .modal_body {
        padding: 30px 45px 35px 45px;
        display: block;
    }

    .que_ans {
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        margin-top: 20px;
    }

    .que_ans > h4 {
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        padding: 10px 20px;
        font-weight: 500;
        background: #e9e9e9;
    }

    .ques_details {
        padding: 0 20px 20px 20px;
    }

    .sender_info .info {
        margin: 0;
    }

    .sender_info .info h5 {
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        color: #2f7eed;
        margin-bottom: 3px;
    }

    .sender_info .info h6 {
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        color: #5a5a5a;
        margin-bottom: 3px;
    }

    .sender_info .info h6 span {
        color: #121525;
        font-weight: 600;
        padding-right: 5px;
    }

    .days {
        display: flex;
        flex-wrap: wrap;
        padding: 0 0 10px;
    }

    .days li {
        padding: 4px 3px;
        flex-grow: 1;
        /* flex: 0 1 25%; */
    }

    .days li label {
        min-width: 80px;
        width: 100%;
        background: #fff;
        border: 1px solid #2f7eed;
        height: 26px;
        border-radius: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 0;
    }

    .days li label span {
        font-size: 11px;
        line-height: 14px;
        color: #2f7eed;
        font-weight: 500;
        margin-right: 5px;
        text-transform: uppercase;
    }

    .days li .goal_btn {
        padding: 0 20px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        line-height: 14px;
        color: #fff;
        background: #2f7eed;
        border-radius: 13px;
    }

    .grp_wpr {
        display: flex;
        margin: 0 -10px;
    }

    .box_item {
        flex: 1 0 50%;
        padding: 0 10px;
    }

    .grp_wpr label {
        border: 1px solid #CBCDD3;
        border-radius: 6px;
        padding: 7px 15px;
        display: flex;
        align-items: center;
    }

    .grp_wpr label p {
        font-size: 13px;
        line-height: 15px;
    }

    :deep(.tab_content)  {
        height: 100%;
    }
</style>
